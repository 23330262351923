import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import InsertImage from '@/components/InsertImage/InsertImage';
import { MAX_ATTACHMENTS_SIZE } from '@/store/mail/modules/drafts';

const { ...props } = InsertImage.props || {};

export default Vue.component('InsertImageWrapper', {
  props,
  computed: {
    ...mapGetters(['totalAttachmentsSize']),
  },
  methods: {
    ...mapActions(['setToastMessage']),
    insertImage(files) {
      const file = files[0];
      if (file) {
        if (
          parseInt(file.size, 10) + this.totalAttachmentsSize >
          MAX_ATTACHMENTS_SIZE
        ) {
          this.setToastMessage({
            message: this.$gettext(
              'The file size of the selected file(s) is too big'
            ),
          });
          return;
        }
        const objectUrl = window.URL.createObjectURL(file);
        this.$emit('inlineImageInserted', { file, objectUrl });
      } else {
        this.setToastMessage({
          message: this.$gettext('Unable to insert image'),
        });
      }
    },
  },
  render(createElement) {
    return createElement(InsertImage, {
      on: {
        insertImage: this.insertImage,
      },
    });
  },
});
