import flattenArray from './flattenArray';

async function handleEntry(entry: Entry): Promise<File[]> {
  if (entry?.isFile) {
    return new Promise((resolve, reject) => {
      (entry as FileEntry).file((file) => resolve([file]), reject);
    });
  } else if (entry?.isDirectory) {
    const dirReader = (entry as DirectoryEntry).createReader();
    return new Promise((resolve, reject) => {
      dirReader.readEntries((entries) => {
        Promise.all(entries.map(handleEntry))
          .then((fileArrays) =>
            resolve(flattenArray(fileArrays) as unknown as File[])
          )
          .catch(reject);
      }, reject);
    });
  }
  throw Error('Unable to handle entry');
}

export default async function flattenFileTree(
  dataTransfer: DataTransfer
): Promise<File[]> {
  if (!dataTransfer.items) {
    return Array.from(dataTransfer.files).filter((file) => file.type !== '');
  }

  //get the items as FileSystemEntries (this allows us to read directory structures)
  const promises = Array.from(dataTransfer.items).reduce((all, item) => {
    const getAsEntry: (this: DataTransferItem) => Entry =
      (item as any).getAsEntry || item.webkitGetAsEntry;
    if (getAsEntry) {
      return [...all, handleEntry(getAsEntry.call(item))];
    } else if (item.kind === 'file') {
      const file = item.getAsFile();
      if (file) {
        return [...all, Promise.resolve([file])];
      }
    }
    return all;
  }, [] as Promise<File[]>[]);

  return Promise.all(promises).then(
    (fileArrays) => flattenArray(fileArrays) as unknown as File[]
  );
}
