function flatten<T>(arr: T[], depth = Infinity): T[] {
  return depth > 0
    ? arr.reduce(
        (flattened: T[], item) =>
          flattened.concat(
            Array.isArray(item) ? flatten(item, depth - 1) : item
          ),
        []
      )
    : [...arr];
}

export default function flattenArray<T>(arr: T[], depth = Infinity): T[] {
  if (typeof arr.flat === 'function') {
    return arr.flat(depth) as T[];
  }

  return flatten(arr, depth);
}
