<template>
  <div class="inline-image-editor">
    <input
      type="file"
      ref="image"
      aria-hidden="true"
      class="visually-hidden"
      accept="image/*"
      @change="insertImage($event.target.files)"
    />
    <ActionBarButton
      :aria-label="inlineImageText"
      v-tooltip="inlineImageText"
      icon="image"
      @click="$refs.image.click()"
      class="rich-text-editor__format-bar-button"
    />
  </div>
</template>

<script type="text/javascript">
  import ActionBarButton from '@/components/ActionBarButton/ActionBarButton';

  export default {
    name: 'InsertImage',
    components: {
      ActionBarButton,
    },
    computed: {
      inlineImageText() {
        return this.$gettext('Insert image');
      },
    },
    methods: {
      insertImage(files) {
        this.$emit('insertImage', files);
        this.$refs.image.value = null; // reset the value of the file input field after each change to allow selecting the same file again
      },
    },
  };
</script>
