<template>
  <div
    v-if="attachment.state !== 'done'"
    :id="id"
    class="inline-image inline-image--container"
    :data-cid="cid"
    :data-state="attachment.state"
    contenteditable="false"
  >
    <ProgressBar
      class="inline-image__progress"
      v-if="attachment.state === 'uploading'"
      :value="attachment.progress"
    />

    <p class="inline-image__error" v-if="attachment.state === 'failed'">
      <translate>Upload failed</translate>
    </p>

    <img :src="loadedSrc" style="max-width: 100%" />
  </div>

  <img
    v-else
    :id="id"
    class="inline-image"
    :data-cid="cid"
    :data-state="attachment.state"
    :src="loadedSrc"
    style="max-width: 100%"
  />
</template>

<script>
  import ProgressBar from '@/components/ProgressBar/ProgressBar';

  export default {
    name: 'InlineImage',
    components: { ProgressBar },
    props: {
      src: {
        type: String,
        required: true,
      },
      attachment: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loadedSrc: this.src,
      };
    },
    computed: {
      id() {
        return this.attachment.state === 'done'
          ? this.attachment.id
          : this.attachment.objectUrl || this.src;
      },
      cid() {
        return this.attachment.content_id || '';
      },
    },
    watch: {
      src(newSrc) {
        const img = new Image();
        img.onload = () => {
          this.loadedSrc = newSrc;
        };
        img.src = newSrc;
      },
    },
    beforeDestroy() {
      window.URL.revokeObjectURL(this.attachment.objectUrl);
    },
  };
</script>

<style src="./InlineImage.scss" lang="scss"></style>
